import { Link } from "react-router-dom";
import { useState } from "react";
import ComingSoonModal from "../../../../../commons/components/comingSoonModal";

var mockData = [
  {
    project_api_plan_id: "devApiPlanId1",
    project_id: "projIdStr",
    project_name: "Sandbox Plan",
    api_plan_duration: 30,
    fixed_rate: 0,
    variable_rate: 0.00,
    api_plan_description: "1 Sandbox Project Per Developer Account",
    is_deleted: 0,
    project_api_plan_environment: "staging",
    features: [
      "1 Sandbox Project Per Developer Account",
      "NETS Online Payment APIs",
      {
        title: "Supported Payment Methods",
        items: ["eNETS", "NETS Click", "NETS QR"],
      },
    ],
  },
  {
    project_api_plan_id: "devApiPlanId2",
    project_id: "projId2Str",
    project_name: "Explorer Plan",
    api_plan_duration: 30,
    fixed_rate: 20,
    variable_rate: 1.50,
    api_plan_description: "Up to 5,000 API calls per month",
    is_deleted: 0,
    project_api_plan_environment: "staging",
    features: [
      "Up to 5,000 API calls per month",
      "OPENAPIPAAS Testbed Server APIs",
      {
        title: "Includes",
        items: ["Lookup Product Web Services", "Omnichannel Transaction Web Services"],
      },
      "NETS Online Payment APIs (UAT Environment)",
      {
        title: "Supported Payment Methods",
        items: ["eNETS", "NETS Click", "NETS QR"],
      },
    ],
  },
  {
    project_api_plan_id: "devApiPlanId3",
    project_id: "projId2Str",
    project_name: "Professional Plan",
    api_plan_duration: 30,
    fixed_rate: 100,
    variable_rate: 2.00,
    api_plan_description: "Up to 25,000 API calls per month",
    is_deleted: 0,
    project_api_plan_environment: "staging",
    features: [
      "Up to 25,000 API calls per month",
      "OPENAPIPAAS Testbed Server APIs",
      {
        title: "Includes",
        items: ["Lookup Product Web Services", "Omnichannel Transaction Web Services"],
      },
      "NETS Online Payment APIs (UAT Environment)",
      {
        title: "Supported Payment Methods",
        items: ["eNETS", "NETS Click", "NETS QR"],
      },
    ],
  },
  {
    project_api_plan_id: "devApiPlanId4",
    project_id: "projId2Str",
    project_name: "Enterprise Plan",
    api_plan_duration: 30,
    fixed_rate: 500,
    variable_rate: 2.50,
    api_plan_description: "Unlimited API calls",
    is_deleted: 0,
    project_api_plan_environment: "staging",
    features: [
      "Unlimited API calls",
      "OPENAPIPAAS Testbed Server APIs",
      {
        title: "Includes",
        items: ["Lookup Product Web Services", "Omnichannel Transaction Web Services"],
      },
      "NETS Online Payment APIs (UAT Environment)",
      {
        title: "Supported Payment Methods",
        items: ["eNETS", "NETS Click", "NETS QR"],
      },
    ],
  },
];

export { mockData };

export default function PlanCard({
  planName,
  price = "$0.00",
  billingCycle = "/month",
  features = [],
}) {
  const [isOpen, setOpen] = useState(false);
  const handleComingSoonClick = () => {
    setOpen(true);
  };

  return (
    <div
      className="bg-white border-gray-500 border rounded-2xl text-black"
      style={{
        height: "inherit",
        width: "100%",
        minHeight: 0,
        minWidth: 0,
        boxShadow: "5px 10px #EBECF0",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        className="p-4"
        style={{
          display: "grid",
          "grid-template-areas": `'text1 text1 text1' 'text2 text2 text2''text3 text3 text3''text4 text4 text4''button1 button1 button1'`,
          "grid-template-columns": "repeat(3, minmax(0, 1fr))",
          gap: 4,
          maxWidth: "inherit",
          textAlign: "left",
        }}
      >
        <div className="font-bold text-gray-700" style={{ gridArea: "text1", fontSize: "30px" }}>
          {planName}
        </div>

        <div className="pt-2 text-4xl font-bold" style={{ gridArea: "text2" }}>
          ${price}
        </div>

        <div className="pt-2 text-xl font-bold text-gray-500" style={{ gridArea: "text3" }}>
          {billingCycle}
        </div>

        {/* <div className="pt-4 text-black" style={{ gridArea: "text4" }}>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            <li style={{ marginBottom: "20px" }}>1 Sandbox Project Per Developer Account</li>
            <li>
              NETS Online Payment APIs
              <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                <li>eNETS</li>
                <li>NETS Click</li>
                <li>NETS QR</li>
              </ul>
            </li>
          </ul>
        </div> */}

        <div className="pt-4 text-black" style={{ gridArea: "text4" }}>
          <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
            {features.map((feature, index) => (
              typeof feature === "object" ? (
                <li key={index} style={{ marginBottom: "10px" }}>
                  <strong>{feature.title}</strong>
                  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                    {feature.items.map((subFeature, subIndex) => (
                      <li key={subIndex}>{subFeature}</li>
                    ))}
                  </ul>
                </li>
              ) : (
                <li key={index} style={{ marginBottom: "10px" }}>{feature}</li>
              )
            ))}
          </ul>
        </div>

        {/* buttons */}
        <div className="button-container" style={{ gridArea: "button1", textAlign: "center" }}>
          {/* <Link to="/developer/project/details"> */}

        </div>
      </div>
      <button
        type="button"
        onClick={handleComingSoonClick}
        className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 py-2 px-12 rounded-full text-sm mb-5"
        style={{ textAlign: "center", width: "80%", margin: "auto" }}
      >
        Get Started Today!
      </button>
      {/* </Link> */}
      {isOpen && <ComingSoonModal setOpenModal={setOpen} />}
    </div>
  );
}
